<template>
  <div>
    <van-nav-bar
      title="喜欢的练习"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="like" v-if="MyFavouritelist.length > 0">
      <div class="list">
        <div
          class="listItem"
          v-for="(item, index) in MyFavouritelist"
          :key="index"
          @click="gotoitem(item)"
        >
          <div>{{ item.task_name }}</div>
          <img
            src="https://lesson.iapeap.com/images/EmotionalValue/like.png"
            alt=""
            @click.stop="getcancelFavourite(item)"
          />
        </div>
      </div>
    </div>
    <div class="nullData" v-if="MyFavouritelist == 0">
      <div class="nullDatactx">
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/kongbox.png"
          alt=""
        />
        <div>暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyFavourite, setFavourite, cancelFavourite ,memberLog} from "../../api/login";

export default {
  data() {
    return {
      name: "",
      MyFavouritelist: [],
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
    };
  },
  created() {
    this.getgetMyFavourite();
  },
  methods: {
    // 点击收藏
    // getsetFavourite(item) {
    //   let data = {
    //     userId: window.localStorage.getItem("userId"),
    //     id: item.id,
    //   };
    //   setFavourite(data).then((res) => {
    //     this.getgetMyFavourite();
    //   });
    // },
    // 取消收藏
    gotoitem(item) {
      console.log(item.task_name);
      console.log(item.id);
      if (
        item.task_name == "入营指南" ||
        item.task_name == "唤醒情绪觉察力" ||
        item.task_name == "如何更好地觉察情绪" ||
        item.task_name == "情绪合理化" ||
        item.task_name == "情绪能量转化" ||
        item.task_name == "对情绪的积极回应" ||
        item.task_name == "应对情绪的方法" ||
        item.task_name == "总结与拓展" ||
        item.task_name == "积极倾听的开始" ||
        item.task_name == "打开倾听的空间" ||
        item.task_name == "捕捉倾听中的信息" ||
        item.task_name == "深层次的倾听——共情" ||
        item.task_name == "倾听中的支持性回应" ||
        item.task_name == "调整倾听中的阻碍" ||
        item.task_name == "沟通表达的力量" ||
        item.task_name == "从客观事实出发" ||
        item.task_name == "对自己的感受负责" ||
        item.task_name == "良好沟通表达的助力——站在对方的视角" ||
        item.task_name == "多提请求，而不是要求" ||
        item.task_name == "应对沟通中的矛盾" ||
        item.task_name == "幽默的积极力量" ||
        item.task_name == "用适当的方式幽默" ||
        item.task_name == "探索属于你的幽默感" ||
        item.task_name == "幽默的化解力" ||
        item.task_name == "幽默的修复力" ||
        item.task_name == "丰富生活中的积极体验"
      ) {
        this.$router.push({
          path: "/setout/handbook",
          query: { item: item },
        });
      }

      if (
        item.task_name == "情绪探照灯" ||
        item.task_name == "情绪命名挑战" ||
        item.task_name == "情绪逻辑师" ||
        item.task_name == "情绪探险家" ||
        item.task_name == "情绪的时光机" ||
        item.task_name == "情绪呼吸阀" ||
        item.task_name == "情绪指南秘籍" ||
        item.task_name == "倾听能量显微镜" ||
        item.task_name == "倾听空间建筑师" ||
        item.task_name == "倾听信号转接员" ||
        item.task_name == "共情对讲机" ||
        item.task_name == "倾听回应能量阀" ||
        item.task_name == "倾听保护伞" ||
        item.task_name == "倾听指南秘籍" ||
        item.task_name == "沟通计划书" ||
        item.task_name == "客观的表达者" ||
        item.task_name == "感受代言人" ||
        item.task_name == "同频输出者" ||
        item.task_name == "请求提出者" ||
        item.task_name == "冲突化解力" ||
        item.task_name == "表达指南秘籍" ||
        item.task_name == "幽默的线索" ||
        item.task_name == "幽默的指南针" ||
        item.task_name == "幽默探索号" ||
        item.task_name == "幽默的降落伞" ||
        item.task_name == "幽默的讲述者" ||
        item.task_name == "生活的积极按钮" ||
        item.task_name == "收获总结"
      ) {
        if (item.is_complated == 1) {
          this.$router.push({
            path: "/setout/ckninjia",
            query: { item: item },
          });
        } else {
          this.$router.push({
            path: "/setout/ninjia",
            query: { item: item },
          });
        }
      }
      if (item.task_name == "情景训练场") {
        this.$router.push({
          path: "/setout/trainingground",
          query: { item: item },
        });
        window.localStorage.setItem("listid", item.id);
      }
      if (item.task_name == "情绪日记") {
        if (item.is_complated !== 1) {
          this.$router.push({
            path: "/emotion/diary",
            query: { item: item },
          });
        } else {
          this.$router.push({
            path: "/emotion/ckdiary",
            query: { item: item },
          });
        }
      }
      if (item.task_name == "对自己的承诺") {
        this.$router.push({
          path: "/setout/mepromise",
          query: { item: item },
        });
      }
      if (item.task_name == "情绪能量测验") {
        console.log(item);
         if (item.id === 5646) {
          this.$router.push({
            path: "/test/worth",
            query: { item: item },
          });
        } else {
          this.$router.push({
            path: "/welcomeH",
            query: { item: item },
          });
        }
      }
    },
    getcancelFavourite(item) {
      console.log(item);
      let data = {
        userId: window.localStorage.getItem("userId"),
        id: item.id,
      };
      cancelFavourite(data).then((res) => {
        this.getgetMyFavourite();
      });
    },
    getgetMyFavourite() {
      let data = { userId: window.localStorage.getItem("userId") };
      getMyFavourite(data).then((res) => {
        this.MyFavouritelist = res.data;
        console.log(this.MyFavouritelist);
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "喜欢的练习",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>

<style lang="less">
// 空数据样式
.nullData {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .nullDatactx {
    color: #464646;
    img {
      width: 200px;
    }
    text-align: center;
  }
}

.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.like {
  padding: 20px 40px;
  .list {
    .listItem {
      cursor: pointer;
      height: 48px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px;
      color: #72acf4;
      margin-bottom: 20px;
      img {
        width: 18px;
        height: 15px;
      }
    }
  }
}
</style>
